/* Existing styles */

/* Hide the chat column on small screens by default */
@media (max-width: 768px) {
    .chat-column {
        display: none;
    }
}

.chat-column.active {
    display: block;
}

.chat-column {
    margin-top: 12px;
    overflow: auto;
    max-height: 450px;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 10px;
    position: relative; /* Add this to position the chat properly */
    background-color: #ffff;
    background-image: url("https://www.transparenttextures.com/patterns/clean-gray-paper.png");
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

#messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    list-style: none;
    margin: 0;
}

.message-input-container {
    display: flex;
    padding: 10px;
    background: #f1f1f1;
    border-top: 1px solid #ccc;
}

.inputType {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.buttonType {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    background-color: #2980b9;
    color: white;
    cursor: pointer;
}

.buttonTypeClose {
    display: inline-flex; /* Change to inline-flex to fit content */
    align-items: center;
    /* gap: 5px; Adjust the gap as needed */
    border-radius: 25px;
    border: none;
    padding: 10px;
    background-color: orangered;
    color: white;
    cursor: pointer;
    /* Remove margin and set margin-left if needed */
    margin: 0;
}

.buttonTypeBack {
    display: inline-flex; /* Change to inline-flex to fit content */
    align-items: center;
    /* gap: 5px; Adjust the gap as needed */
    border-radius: 25px;
    border: none;
    padding: 10px;
    background-color: grey;
    color: white;
    cursor: pointer;
    /* Remove margin and set margin-left if needed */
    margin: 0;
}

.unread {
    font-weight: bold;
}

.badge {
    margin-left: 10px;
}

.buttonTypeClose .button-text {
    margin-left: 5px; /* Adjust the spacing as needed */
}

.friends-list {
    overflow: auto;
    margin-top: 12px;
    max-height: 450px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #ffff;
    background-image: url("https://www.transparenttextures.com/patterns/clean-gray-paper.png");
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) { /* Adjust the max-width as per your responsive design needs */
    .hide-on-active-chat {
        display: none;
    }
    .show-full-chat {
        display: block;
        width: 100%;
    }
    .friends-list {
        display: none;
    }

    .friends-list.active {
        display: block;
    }

    .chat-column {
        display: none;
    }

    .chat-column.active {
        display: block;
    }
}

/*#messages {
    list-style-type: none;
    padding: 0;
    max-width: 600px;
    margin: auto;
}*/

.message {
    display: flex;
    padding: 5px;
    margin: 5px;
    border-radius: 15px;
    width: fit-content;
    max-width: 70%;
}

.messenger-container {
    display: flex;
    align-items: center; /* Keeps image and text aligned vertically in the center */
}

.imgmessenger {
    cursor: pointer;
    width: 30px;
    /*margin: 5px;*/
    border-radius: 50%;
    background: white;
    box-shadow: 0px 2px #e4e4e4;
}

.text-container {
    display: flex;
    flex-direction: column;
}

.sent {
    /*background-color: #2980b9;*/
    text-align: left;
    /*color: white;*/
    margin-left: auto;
    padding-right: 10px;
}

.received {
    /*background-color: #e5e5ea;*/
    text-align: left;
    color: black;
    padding-right: 10px;
    margin-right: auto;
}

.inputType, .buttonType {
    padding: 10px;
    margin: 5px;
    border-radius: 25px;
}

.inputType {
    width: calc(100% - 130px);
}

.buttonType {
    width: 100px;
}

/*------------------------------------*---------------/
/* Date separator */
.date-separator {
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
    color: #555;
    position: relative;
}

.date-separator span {
    background-color: #f9f9f9;
    padding: 0 10px;
    position: relative;
    z-index: 1;
}

.date-separator:before {
    content: "";
    display: block;
    height: 1px;
    background-color: #ccc;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
}

/* Message content */
.message-content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

/* Messenger avatar */
.messenger-avatar {
    margin-right: 10px;
}

.imgmessenger {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
/*  emoji chat*/
.emoji-picker-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.emoji-picker-content {
    background: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
}

.close-emoji-picker {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

/* Message text */
.message-text {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    max-width: 75%;
    background-color: #ffff;
    background-image: url("https://www.transparenttextures.com/patterns/clean-gray-paper.png");
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
    word-wrap: break-word; /* Allows long words to break and wrap onto the next line */
    white-space: pre-wrap; /* Maintains whitespace but allows wrapping */
    overflow-wrap: break-word; /* Ensures links and long words break */
}

/* Sent message text */
.sent-text {
    /*background-color: #d1e7dd;*/
    background-color: #2980b9;
    color: white;
    margin-right: 10px;
    border-radius: 15px 0px 10px 10px;
}

/* Received message text */
.received-text {
    background-color: #f1f1f1;
    border-radius: 0px 15px 10px 10px;
}

/* Message time */
.message-time {
    margin-top: 5px;
    font-size: 0.75em;
    text-align: right;
}

.fixed-header {
    position: sticky;
    top: 0;
    z-index: 100; /* Ensure it stays above other content */
    padding: 15px;
    border-radius: 25px;
    background-color: white;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
/*---------------------------------------------------------*/

.message.sent .message-content {
    flex-direction: row-reverse;
}

.message.received .message-content {
    flex-direction: row;
}
