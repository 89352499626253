/* .about-container-landing {
    color: #333;
    line-height: 1.6;
    padding: 20px;
    max-width: 1200px;
    margin: 80px auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
} */
.cover-secs-landing {
    position: relative;
    margin-top: 65px;
    /* width: 100%;
    height: 150px; Adjusted height for better visibility or */
    /* margin-bottom: 40px; */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-header {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background to the text */
    padding: 20px;
    border-radius: 8px;
}

.landing-header h1 {
    /* font-size: 48px; */
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 10px;
    /* text-shadow: 0 0 5px #1877f2, 0 0 10px #1877f2, 0 0 20px #1877f2; */
    /* text-shadow */
    font-size: 100px; /* Adjust size as needed */
    font-weight: bold;
    color: #fff; /* Pink color for the text */
    text-shadow: 
    -6px 6px 0 black, 
    -8px 8px 0 #555; 
    /* end text-shadow */
}

.text-shadow-custom {
  font-family: 'Orbitron', sans-serif;
  margin-bottom: 10px;
  font-size: clamp(35px, 5vw, 100px); /* Dynamic font size */
  font-weight: bold;
  color: #fff; /* Text color */
  text-shadow: 
      calc(-0.06em) calc(0.06em) 0 black, 
      calc(-0.06em) calc(0.06em) 0 #555; 
}

.landing-header h2 {
    color: red; /* Keep the red color as specified */
    font-size: 24px;
    margin-bottom: 10px;
}

.landing-header p {
    font-size: 18px;
    color: #fff; /* Light text color for better contrast */
}


.background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70%;
    padding: 60px 0; /* Adjust padding to control the height of the section */
    position: relative;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.overlay {
    position: relative;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8); /* Light background for readability */
    border-radius: 8px;
    padding: 40px;
    text-align: center;
}

h2, p {
    color: #333;
}

/* .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}

.row {
    display: flex;
    justify-content: center;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
} */

@media (max-width: 768px) {
    .background-image {
        padding: 40px 0; /* Adjust padding for smaller screens */
    }
}


.text-content {
    flex: 1; /* Allow text content to take the remaining space */
}

.text-content h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
}

.text-content p {
    font-size: 18px;
    color: #555;
}



.about-features {
    margin-bottom: 20px;
    margin-top: -30px;
}

.about-features h2 {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 0px;
}

.feature-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.feature-item {
    flex: 1 1 calc(25% - 40px);
    margin: 20px;
    /* padding: 20px; */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item h3 {
    font-size: 24px;
    color: #333;
    margin: 20px 0 10px;
}

.feature-item p {
    font-size: 16px;
    color: #555;
}

.feature-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.feature-image {
    width: 100%;
    max-width: 200px;
    margin: 0 auto 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
/* Start Css fro landing-vision */
.landing-vision {
  padding: 30px;
  background-color:#fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  gap: 2rem;
}

.landing-vision .lv-image {
  flex: 1;
  order: 2;
  text-align: center;
}

.landing-vision .lv-text {
  flex: 1;
  order: 1;
  text-align: center;
  margin-top: 20px;
}

.landing-vision .lv-text h2 {
  font-size: 36px;
  font-family: 'Orbitron', sans-serif;
  color: #555;
  margin-bottom: 70px;
}

.landing-vision .lv-text p {
  font-size: 20px;
  margin: 60px;
  color: #555;
}

.landing-vision .lv-text button {
  background-color: #007bff;
  color: white;
  padding: 15px 25px;
  border: 2px solid black;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  margin-top: 10px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .landing-vision {
    flex-direction: column; /* Stack items vertically */
    padding: 20px;
    gap: 1rem;
  }

  .landing-vision .lv-text h2 {
    font-size: 28px; /* Reduce font size */
    margin-bottom: 20px;
  }

  .landing-vision .lv-text p {
    font-size: 16px; /* Smaller font */
    margin: 20px 10px; /* Less spacing */
  }

  .landing-vision .lv-text button {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .landing-vision .lv-image img {
    width: 100%; /* Make image responsive */
    height: auto;
  }
}
/* End Mobile Responsible for landing-vision */

.landing-join-us {
    text-align: center;
    /* margin-top: 10px; */
    /* padding: 0; */
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
    margin-bottom: 20px;
    /* background: linear-gradient(135deg, #b5c9e3, #f7f8fa 50%, hsl(48, 96%, 89%) 100%) !important; */
}

.landing-join-us h2 {
  color: #333;
  margin-bottom: 40px;
  transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition */
}

.landing-join-us h2:hover {
  color: #007BFF; /* Change to your desired hover color */
  text-decoration: underline; /* Add underline */
  cursor: pointer; /* Change cursor to pointer */
}


.landing-join-us p {
    font-size: 20px;
    color: #555;
    padding: 20px;
    margin-bottom: 30px;
}

.join-us-image-placeholder {
  position: relative;
  margin: 0;                
  /* padding: auto; */
  width: 100%;
  /* height: 400px;            */
  /* background-color: green;   */
  /* overflow: hidden;         */
  /* left: 50%;                */
  /* transform: translateX(-50%); */
  /* box-sizing: border-box; */
}

.join-us-image-placeholder img {
  width: 100%;   
  height: 100%;  
  object-fit: cover;
}


/* Responsive Design */
@media (max-width: 1200px) {
    .feature-item {
        flex: 1 1 calc(50% - 40px);
    }
}

@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
    }

    .about-image-placeholder {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .feature-item {
        flex: 1 1 100%;
        margin: 20px 0;
    }
}

/* end sign button css */

.landing-detail-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* border: 2px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9; */
    /* margin-top: -5px; */
    /* margin-left: 20px;
    margin-right: 20px; */
    /* margin-bottom: 40px; */
    background: linear-gradient(135deg, #b5c9e3, #f7f8fa 50%, hsl(48, 96%, 89%) 100%) !important;
    /* background: linear-gradient(-45deg, hsl(48, 96%, 89%), #f7f8fa 50%, #b5c9e3 100%) !important; */

    
  }
  @keyframes glowText {
    0% {
      text-shadow: 0 0 5px #007bff, 0 0 5px #007bff, 0 0 5px #007bff;
    }
    50% {
      text-shadow: 0 0 5px #007bff, 0 0 7px #007bff, 0 0 10px #007bff;
    }
    100% {
      text-shadow: 0 0 5px #007bff, 0 0 5px #007bff, 0 0 5px #007bff;
    }
  }
  
  .landing-detail-profile h1 {
    margin: 20px;
    font-size: 3.5rem; /* Slightly larger font for a modern look */
    color: #333; /* Dark gray text */
    text-align: center; /* Centered text */
    position: relative; /* Required for pseudo-elements */
    padding-bottom: 10px;
    /* font-weight: 600; */
    /* color:#007bff; */
    font-family: 'Orbitron', sans-serif;
    /* animation: glowText 2s infinite alternate; */
    /* text-shadow: 0 0 5px #1877f2, 0 0 10px #1877f2, 0 0 20px #1877f2; */
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    /* text-transform: uppercase; Optional: Adds a clean uppercase look */
    /* letter-spacing: 1px; */
  }
  
  /* Adding minimal glowing lines */
  .landing-detail-profile h1::before,
  .landing-detail-profile h1::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 90px; /* Short glowing lines */
    background: #007bff; /* Blue color for glowing lines */
    box-shadow: 0 0 8px #007bff; /* Subtle glow effect */
  }
  
  .landing-detail-profile h1::before {
    left: 10%; 
  }
  
  .landing-detail-profile h1::after {
    right: 10%; 
  }
  
  
  .landing-detail-profile .card-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
    /* background: green !important; */
    flex-wrap: wrap; /* Allow cards to wrap to the next line on small screens */
  }
  
  .landing-detail-profile .card {
    background: #fff;
    /* border: 2px solid #ddd; */
    /* border-radius: 8px; */
    width: 30%;
    text-align: center;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 30px; /* Need to be remove */
    position: relative;
  }
  .landing-detail-profile .card-image {
    width: 100%;
    max-height: 260px;
    object-fit: cover; /* Ensures the image scales without distortion */
    overflow: hidden; 
  }
  .landing-detail-profile .card h2 {
    color: #007bff;
    margin-top: 5px;
    font-family: 'Orbitron', sans-serif;
    font-size: 1.5rem;
  }
  
  .landing-detail-profile .card p {
    padding: 5px;
    margin-bottom: 10px;
  }
  .landing-detail-profile .button-cta {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: 2px solid whitesmoke;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2rem;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    border-radius: 25px;
    margin-top: 10px;
    /* transition: background-color 0.3s ease, transform 0.3s ease; */
    position: relative;
    /* top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    opacity: 1; /* Ensure the button is fully opaque */
  }
  .landing-detail-profile .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  .landing-detail-profile .card.selected {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
    opacity: 1; /* Make the selected card semi-transparent */
  }
  
  .landing-detail-profile .card.selected .submit-button {
    display: block; /* Ensure the button is visible */
  }  
  .landing-detail-profile .submit-button  {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    opacity: 1; /* Ensure the button is fully opaque */
  }
  
  .landing-detail-profile .card.selected .submit-button {
    display: block;
  }
  
  .landing-detail-profile .submit-button:hover {
    background-color: #0056b3;
    transform: translate(-50%, -50%) translateY(-3px);
  }
  
  .landing-detail-profile .error-message {
    color: red;
    margin-top: 10px;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .landing-detail-profile .card-container {
      flex-direction: column; /* Stack cards vertically on small screens */
      align-items: center;
    }
  
    .landing-detail-profile .card {
      width: 80%; /* Make cards wider on small screens */
    }
  }
  
  @media (max-width: 480px) {
    .landing-detail-profile h1 {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
    }
  
    .landing-detail-profile .submit-button {
      font-size: 1rem; /* Adjust button size for smaller screens */
      padding: 8px 16px; /* Adjust padding for smaller screens */
    }
  }

  /* OLD CSS */
  /* sigin in csss */
/* .floating-signin-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: green;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 1000; 
}

.floating-signin-btn:hover {
  background-color: #0056b3;
  color: white;
}

.floating-signin-btn .fa-home {
  margin-right: 8px;
} */
