.privacy-policy {
    max-width: 800px;
    margin: 80px auto;
    padding: 20px;
    box-shadow: 10px 4px 58px 0 rgba(51, 30, 30, 0.2);
    border-radius: 20px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .privacy-policy h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .privacy-policy p {
    margin-bottom: 20px;
  }
  
  .privacy-policy ul {
    margin-bottom: 20px;
  }
  
  .privacy-policy li {
    margin-bottom: 10px;
  }
  