/* Custom style for form elements */
.custom-form-control {
    width: 100%; /* Set the width to 100% to make all form elements the same width */
    height: 45px; /* Adjust the height as needed */
    margin: 7px 0;
    padding: 7px 15px; /* Add padding on the top/bottom and left/right */
    border-radius: 25px;
  }
  
  .custom-modal-body {
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto; /* Add a scrollbar if needed */
  }
  
  .max-height {
    max-height: 500px; /* Adjust the height as needed */
    overflow-y: auto; /* Add a scrollbar if needed */
  }
  .hidden-input {
    display: none;
  }
  .dropzone {
    /* border: 2px dashed #007bff; */
    border : 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 25px;
    box-shadow: 3px 4px 0px  rgba(0, 0, 0, 0.1);
  }
  
  /* styles.css */
  .custom-modal-header {
    background-color: #3498db;
    color: white;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  /* styles.css */
  .custom-modal-width {
    max-width: 100%;
    width: 100%;
    height: 100%; /* Set height to 100% to ensure it takes the full height of the screen */
  }
  /*.buttonWithStyles {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 38px;
  }
  */
  .custom-modal-header .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .custom-modal-header .header-title {
    flex-grow: 1; /* Allow the title to grow and take available space */
  }
  
  .custom-modal-header .header-image img {
    max-width: 100%; /* Ensure the image doesn't exceed its container width */
    height: auto; /* Maintain the image's aspect ratio */
  }
  
  
  