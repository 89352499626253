.about-container {
    color: #333;
    line-height: 1.6;
    padding: 20px;
    max-width: 1200px;
    margin: 80px auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.cover-secs {
    position: relative;
    width: 100%;
    height: 150px; /* Adjusted height for better visibility */
    margin-bottom: 40px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-header {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff; /* Set default text color to white for visibility */
    background: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background to the text */
    padding: 20px;
    border-radius: 8px;
}

.about-header h1 {
    font-size: 48px;
    margin-bottom: 10px;
}

.about-header h2 {
    color: red; /* Keep the red color as specified */
    font-size: 24px;
    margin-bottom: 10px;
}

.about-header p {
    font-size: 18px;
    color: #ddd; /* Light text color for better contrast */
}


.background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 70%;
    padding: 60px 0; /* Adjust padding to control the height of the section */
    position: relative;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.overlay {
    position: relative;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8); /* Light background for readability */
    border-radius: 8px;
    padding: 40px;
    text-align: center;
}

h2, p {
    color: #333;
}

/* .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}

.row {
    display: flex;
    justify-content: center;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
} */

@media (max-width: 768px) {
    .background-image {
        padding: 40px 0; /* Adjust padding for smaller screens */
    }
}


.text-content {
    flex: 1; /* Allow text content to take the remaining space */
}

.text-content h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
}

.text-content p {
    font-size: 18px;
    color: #555;
}



.about-features {
    margin-bottom: 20px;
    margin-top: -30px;
}

.about-features h2 {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 0px;
}

.feature-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.feature-item {
    flex: 1 1 calc(25% - 40px);
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item h3 {
    font-size: 24px;
    color: #333;
    margin: 20px 0 10px;
}

.feature-item p {
    font-size: 16px;
    color: #555;
}

.feature-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.feature-image {
    width: 100%;
    max-width: 200px;
    margin: 0 auto 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.about-vision {
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 50px;
}

.about-vision h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
}

.about-vision p {
    font-size: 18px;
    color: #555;
}

.about-join-us {
    text-align: center;
}

.about-join-us h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
}

.about-join-us p {
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
}

.join-us-image-placeholder {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.join-us-image-placeholder img {
    width: 100%;
    max-width: 1200px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 1200px) {
    .feature-item {
        flex: 1 1 calc(50% - 40px);
    }
}

@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
    }

    .about-image-placeholder {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .feature-item {
        flex: 1 1 100%;
        margin: 20px 0;
    }
}

/* sigin in csss */
.floating-signin-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: green;
    color: white;
    padding: 10px 20px;
    border-radius: 50px;
    text-decoration: none;
    font-size: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    z-index: 1000; /* Ensure it stays on top */
  }
  
  .floating-signin-btn:hover {
    background-color: #0056b3;
    color: white;
  }
  
  .floating-signin-btn .fa-home {
    margin-right: 8px;
  }
  
/* end sign button css */