/**{.App {
  text-align: center;
  background-color: white;
  color:black;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-link {
  color: #61dafb;
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
/* NAV BAR CSS */
/* The navbar container */
/* The navbar container */
/*.navbar {
  background-color: #333;
  color: #fff;
  padding: 10px;
}

/* List items inside the navbar */
/*.navbar li {
  list-style-type: none;
  float: left;
}

/* END NAV BAR CSS */
/* My own css */

body {
  /* font-family: var(--font-default);
  color: var(--color-default);*/
  background-color: white;
  color:black;
  /* background-image: url("https://www.transparenttextures.com/patterns/cubes.png"); */
}

.dark-mode {
  background-color: black;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
  color: white;
}
.card-container {
  display: block;
  flex-wrap: wrap;
}
.custom-navbar {
  background-color: #2980b9 !important;
  width: 100%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
   .nav-link {
    text-align: left;
  }
}
/*.custom-toggler {
  border-color: white;
}*/
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.white-text {
  color: white !important;
}

.referral {

  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 150px auto;
  background-color: #f9f9f9;
  text-align: center;
}

.referral h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.referral input[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  font-size: 1em;
  box-sizing: border-box;
}

.referral button {
  padding: 10px 15px;
  background-color: #337ed4;
  color: rgb(23, 20, 20);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.referral button:hover {
  background-color: #155dba;
}

.referral .share-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.referral .share-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.referral .share-buttons button:hover {
  opacity: 0.8;
}

@media (max-width: 600px) {
  .referral {
    padding: 10px;
    max-width: 100%;
  }

  .referral h3 {
    font-size: 1.2em;
  }

  .referral input[type="text"] {
    font-size: 0.9em;
    padding: 8px;
  }

  .referral button {
    font-size: 0.9em;
    padding: 8px 10px;
  }

 

  .referral .share-buttons button {
    margin-bottom: 10px;
  }
}


/*
.topnav {
overflow: hidden;
background-color: #e9e9e9;
}

.topnav a {
float: left;
display: block;
color: black;
text-align: center;
padding: 14px 16px;
text-decoration: none;
font-size: 17px;
}

.topnav a:hover {
color: black;
}

.topnav a.active {
background-color: #2196F3;
color: white;
}

.topnav input[type=text] {
float: left;
padding: 6px;
margin-top: 8px;
margin-right: 16px;
border: none;
font-size: 17px;
}

@media screen and (max-width: 600px) {
.topnav a, .topnav input[type=text] {
  float: none;
  display: block;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 14px;
}

.topnav input[type=text] {
  border: 1px solid #ccc;  
}
} */

.card:hover {
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.22);
}
form{
overflow: hidden;
}
.dashbord{
  display: flex;
  justify-content: space-between;
  border: 1px solid black; /* add a border to the outer container for visualization */
}
.columns1{
  display: inline-block;
  width: 90%;
  box-sizing: border-box; /* include border in width calculation */
}
.bg-img {
  /* The image used */
  background-image: url("/public/img/4089.jpg");

  min-height: 380px;
  display: inline-block;
  width: 20%;
  /* Center and scale the image nicely 
  background-color: red;*/
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /*background-attachment: fixed;*/
  height: 100%;
  left: 0;
}
@media (max-width: 768px) {
  .video-wrapper {
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
  }
}
/* For Feedback design component */
.feedback-list {
  display: flex;
  flex-wrap: wrap;
}

.feedback-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.feedback-item img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.feedback-content {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .feedback-item {
    width: 50%;
  }
}
/* end Css for Feedback */
.containers {
  position: absolute;
  box-shadow: 0 4px 58px 0 rgba(79, 52, 52, 0.2);
  right: 0;
  margin: 20px;
  margin-bottom: 56px;
  max-width: 300px;
  padding: 16px;
  background-color: white;
  border-radius: 25px;
  border: 2px solid #04AA6D;
}
/*.profile {
  position: absolute;
  box-shadow: 0 4px 58px 0 rgba(79, 52, 52, 0.2);
  right: 0;
  margin: 20px;
  margin-bottom: 56px;
  max-width: 300px;
  padding: 16px;
  background-color: white;
  border-radius: 25px;
}
a:hover {
color: greenyellow;
}
a{
  text-decoration: none;
} 
*/

.avatar{
  width:15%;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
  /* margin:10px;*/
}
/* Set a style for the submit button 
.btn {
  background-color: #2980b9;
  align-items: center;
  color: white;
  margin-top: 15px;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 15%;
  opacity: 0.9;
}

.btn:hover {
  opacity: 1;
}*/
/* Add styles to the form container */
.contain {
  position: relative;
  display: inline-flex;
  right: 0;
  margin: 20px;
  max-width: 300px;
  padding: 16px;
  background-color: white;
}
/* Full-width input fields */
/* input {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
} 
textarea,input[type=email],input[type=text]:focus, input[type=password]:focus {
  background-color: #ddd;
  outline: none;
} */
.card {
  display: inline-block;
  width: 23%;
  height: 300px;
  margin: 1%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #2980b9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}
/*----------Nav mobile-----------*/
@media (max-width: 991px) {
  .navbar-collapse .nav-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;
  }

  .navbar-collapse .nav-link.logout-link {
    border-bottom: none;
  }
}



/* For screens smaller than 768px */
@media (max-width: 467px) {
  .card {
    width: 48%;
    margin: 2%;
  }
}

/* For screens larger than 768px */
@media (min-width: 768px) {
  .card {
    width: 23%;
    margin: 1%;
  }
}

.small-modal {
  position: fixed;
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.small-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content input[type="file"] {
  margin: 10px 0;
}

/*.modal-content  {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 4px;
}*/

.modal-content button:hover {
  background-color: #45a049;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.user-fw-status li {
  list-style: none;
  margin-bottom: 10px;
}


/* The Modal (background)
.modal {
display: flex; 
position: fixed;
z-index: 1;
padding-top: 100px; 
left: 0;
top: 0;
width: 100%; 
height: 100%; 
overflow: auto; 
background-color: rgb(0,0,0); 
background-color: rgba(0,0,0,0.4); 
justify-content: center;
align-items: center;
}
 */
/* Modal Content 
.modal-content {
background-color: #fefefe;
margin: 15% auto; 
padding: 20px;
border: 1px solid #04AA6D;
border-radius: 25px;
max-width: 80%;
}*/

/* The Close Button 
.close {
color: #aaaaaa;
float: right;
font-size: 48px;
font-weight: bold;
}

.close:hover,
.close:focus {
color: #000;
text-decoration: none;
cursor: pointer;
}*/
/* for video */
/* #carousel {
position: relative;
width: 600px;
height: 400px;
overflow: hidden;
}*/
/* ProfileCard.css */

.user-card {
  max-width: 700px;
  width: 100%; /* Ensure it takes up to 700px but is responsive */
  margin: 20px auto; /* Adds margin around the card */
  box-shadow: 0 14px 8px rgba(0,0,0,0.1);
  border-radius: 0.25rem;
  overflow: hidden; /* Keeps all content within the rounded corners */
  border: none;
}

.user-card img {
  width: 100%; /* Full width of its column */
  height: auto; /* Maintain aspect ratio without stretching */
}

.user-card .row {
  no-gutters: true; /* Reduces spacing between columns */
}

.user-card .col-md-4 {
  padding: 0; /* Removes padding if needed */
}

.user-card .col-md-8 {
  padding: 20px; /* Adds at least 20px padding */
}

.info-item {
  display: flex;
  flex-direction: column-reverse; /* Flips the order of flex items */
  align-items: start; /* Aligns items to the start of the flex-direction */
}

.info-data {
  font-size: 1.4rem; /* Larger font size for the data */
  color: #000; /* Optional: color for emphasis */
}

.info-label {
  font-size: 0.8rem; /* Smaller font size for the label */
  color: #666; /* Lighter, subtler color */
}
/* For small screen*/
/* Responsive styling adjustments for the card */
@media (max-width: 576px) {
  .user-card .col-md-4 {
    display: none; /* Hide video icon column on small screens */
  }

  .fa-play-circle {
    font-size: 1rem; /* Adjust icon size for small screens */
    display: inline-block; /* Ensure it displays inline with text */
  }

  .card-title, .info-data {
    font-size: 1.0rem; /* Adjust font sizes for readability on smaller screens */
    font-weight: bold;
  }
  .info-label {
    font-size: 0.9rem; /* Adjust font sizes for readability on smaller screens */
  }
  .info-item {
    padding: 10px 0; /* Adjust padding for better spacing */
  }
}


/* End of Small screen css */

/*like- commerter */
.like-comer {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.like-comer li {
  display: flex;
  align-items: center;
}

.like-comer a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  margin-right: 10px;
  font-size: 14px;
}

.like-comer a:hover {
  text-decoration: underline;
}

.like-comer img {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.like-comer span {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .like-comer {
    justify-content: space-between;
  }

  .like-comer li:last-child {
    display: none;
  }

  .like-comer a {
    margin-right: 0;
    font-size: 12px;
  }

  .like-comer img {
    display: none;
  }
}
/* end like- Commerter */
/* Styling for the container */

/* add comment css section*/

.comment-section {
  border: 2px solid #ddd;

  border-radius: 0.25rem;
  padding: 10px;
  margin-bottom: 20px;
}

.comment-section a.plus-ic {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #aaa;
  margin-right: 10px;
  font-size: 18px;
}

.comment-section a.plus-ic:hover {
  background-color: #ddd;
  color: #555;
}

.comment-section .comment-sec {
  margin-top: 10px;
}
.comment-item {
  position: relative;
  padding-bottom: 10px;
}
.comment-item .comment-replies {
  margin-left: 40px;
  border-left: 2px solid #ccc;
  padding-left: 10px;
}
.comment-section .comment-item {
  margin-bottom: 10px;
  margin-right: 15px;
  background-color: #f5f5f5;
  border-radius: 15px;
}

.comment-section .comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.comment-section .comment-header img {
  border-radius: 50%;
  margin-right: 10px;
}
.comment-header span {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

.comment-header span img {
  width: 10px;
  margin-right: 5px;
}

.comment-section .comment-user {
  font-weight: bold;
}

.comment-section .comment-text {
  margin-left: 40px;
  padding: 5px;
}

.comment-section .post-comment {
  margin-top: 20px;
}

.comment-section .comment_box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
}
.reply-form {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.reply-form form {
  display: flex;
  width: 100%;
}

.reply-form input[type="text"] {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.reply-form button {
  padding: 10px 15px;
  background-color: #1877F2;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .reply-form {
    flex-direction: column;
    align-items: stretch;
    padding: 5px;
  }

  .reply-form form {
    flex-direction: column;
    width: 100%;
  }

  .reply-form input[type="text"] {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .reply-form button {
    width: 100%;
    padding: 10px;
  }
}

.comment-section .comment_box form {
  display: flex;
}

.comment-section .comment_box input[type="text"],
.comment-section .comment_box select {  /* Adding select here */
  flex: 1;
  margin-right: 10px;
  padding: 5px;
  font-size: 14px;
  border-radius: 15px;
}
/*------------------------*/
@media (max-width: 600px) {
  .comment-section .comment_box form {
    flex-direction: column; /* Stack form elements vertically on small screens */
  }

  .comment-section .comment_box input[type="text"],
  .comment-section .comment_box select {
    margin-right: 0; /* Remove right margin on mobile */
    margin-bottom: 10px; /* Add space between vertically stacked elements */
  }
  
  .comment-section .comment-text {
    margin-left: 10px; /* Reduce margin for smaller screens */
  }
}

/*------------------------*/
.buttonMore{
  border: none;
  border-radius: 4px;
  margin-top: 5px;
  background-color: #2980b9;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}
.comment-section .comment_box button[type="submit"] {
  border: none;
  border-radius: 10px;
  background-color: #2980b9;
  color: #fff;
  padding: 5px 10px;
  margin-left: 1px;
  font-size: 14px;
  cursor: pointer;
}

.comment-section .comment_box button[type="submit"]:hover {
  background-color: #1568c7;
}

/**/

/* feed post facebook */
/* .post-container {
width: 80%;
margin: 0 auto;
}

.user-info {
display: flex;
align-items: center;
}

.user-info img {
width: 50px;
height: 50px;
border-radius: 50%;
margin-right: 10px;
}

.username {
font-weight: bold;
}

.post-content img {
width: 50%;
}

.post-options {
display: flex;
justify-content: flex-end;
}

.like-btn, .comment-btn {
background-color: blue;
color: white;
padding: 5px 10px;
border: none;
cursor: pointer;
margin-right: 10px;
} */

/* -- loading css-- */
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: greenyellow transparent green transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
/*-------*LOADING 2*------*/

.lds-ellipsis {
  position: absolute; /* Add positioning to child loading animation */
  top: 50%; /* Position at the vertical center of the button */
  left: 50%; /* Position at the horizontal center of the button */
  transform: translate(-50%, -50%); /* Center the animation */
  width: 80px;
  height: 80px;
  /* border: 2px solid blue;*/
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* facebook loading*/
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 15px;
  }
}


.reply-info {
  display: flex;
  align-items: center;
}

.user-id {
  font-size: 12px;
  color: gray;
  margin-right: 5px;
  font-weight: bold;
}
.reply-line {
  position: relative;
  height: 30px; /* Adjust height as needed for your design */
  width: 2px; /* Keep line thin */
  background-color: transparent; /* Transparent main line */
  margin: 0 10px; /* Space from text or other elements */
}

.reply-line::before,
.reply-line::after {
  content: '';
  position: absolute;
  width: 2px; /* Match the main line width */
  background-color: gray; /* Color of the line */
}

.reply-line::before {
  left: 0;
  top: 0;
  height: 15px; /* Adjust for top curvature */
  transform: skewY(-20deg); /* Slight skew for curve */
  transform-origin: top left;
}

.reply-line::after {
  left: 0;
  bottom: 0;
  height: 15px; /* Adjust for bottom curvature */
  transform: skewY(20deg); /* Opposite skew for smooth curve */
  transform-origin: bottom left;
}

.reply-section form {
  display: flex;
  align-items: center;
}

.replyButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0px;
    font: inherit;
    color: #007bff;
    margin-right: 20px;
}
.reply-section input[type="text"] {
  width: 100%;
  padding: 5px;
  border: 0px solid #ccc;
  border-radius: 4px;
}

.reply-section button[type="submit"] {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 7px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.reply-section button[type="submit"]:hover {
  background-color: #45a049;
}

.reply-section .replies {
  margin-top: 10px;
}

.reply-section .reply-item {
  margin-bottom: 10px;
}

.reply-section .reply-content {
  display: flex;
  align-items: flex-start;
}

.reply-section .reply-content img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.reply-section .reply-text {
  flex: 1;
}

.reply-section .reply-text p {
  margin: 0;
}

.reply-section .reply-info {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
}

.reply-section .reply-line {
  width: 1px;
  height: 20px;
  background-color: gray;
  margin: 0 10px;
}
/* Responsive adjustments with a media query */
@media (max-width: 600px) {
  .reply-section input[type="text"]
  {
    margin-left: 35px;
  }
  .replayButtonSection, .replies, .content-text{
    margin-left: 34px;
  }
  .reply-section form {
  display: flex;
  align-items: center;
  }
}
/* FOr FOLLOING PART 
.widget {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
}

.sd-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.sd-title h3 {
  font-size: 24px;
  color: #333;
}

.sd-title i {
  font-size: 20px;
  cursor: pointer;
}

.jobs-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.job-info {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background: #f9f9f9;
  transition: background 0.3s ease;
}

.job-info:hover {
  background: #eaeaea;
}

.job-details {
  display: flex;
  align-items: center;
  gap: 20px;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.job-details h3 {
  font-size: 20px;
  color: #333;
  margin: 0;
}

.job-details p {
  font-size: 16px;
  color: #777;
  margin: 0;
}
*/
.complete-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-top: 90px;
  margin-left: 20px;
  margin-right: 20px;
}

.complete-profile h1 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
  text-align: center;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.complete-profile .card-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow cards to wrap to the next line on small screens */
}

.complete-profile .card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 30%;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px; /* Add margin for spacing between cards */
  position: relative;
}

.complete-profile .card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.complete-profile .card.selected {
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  opacity: 1; /* Make the selected card semi-transparent */
}

.complete-profile .card.selected .submit-button {
  display: block; /* Ensure the button is visible */
}

.complete-profile .card h2 {
  margin-top: 10px;
  color: #007bff;
}

.complete-profile .card p {
  flex-grow: 1;
  margin-bottom: 10px;
}

.complete-profile .card-image {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
}

.complete-profile .submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  opacity: 1; /* Ensure the button is fully opaque */
}

.complete-profile .card.selected .submit-button {
  display: block;
}

.complete-profile .submit-button:hover {
  background-color: #0056b3;
  transform: translate(-50%, -50%) translateY(-3px);
}

.complete-profile .error-message {
  color: red;
  margin-top: 10px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .complete-profile .card-container {
    flex-direction: column; /* Stack cards vertically on small screens */
    align-items: center;
  }

  .complete-profile .card {
    width: 80%; /* Make cards wider on small screens */
  }
}

@media (max-width: 480px) {
  .complete-profile h1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .complete-profile .submit-button {
    font-size: 1rem; /* Adjust button size for smaller screens */
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }
}

/*---------------------------------*/
.confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 100px 20px 20px 20px;
  padding: 2rem;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.illustration {
  max-width: 30%;
  height: auto;
  margin: 0 auto;
}

.form-container {
  max-width: 80%;
  margin: 0 auto;
}

.form-container h1 {
  font-size: 2.0rem;
  margin: 20px;
  color: #2980b9;
  font-weight: bold;
}

.form-container p {
  font-size: 1.2rem;
  color: #555;
}

.form-container form {
  text-align: center;
  padding: 0 20px;
}

.form-container .form-control,
.form-container .btn-primary {
  margin: 10px auto;
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 1.2rem;
  box-sizing: border-box;
}

.form-container .btn-primary {
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container .btn-primary:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 1.2rem;
}

/* Media queries for responsive design */
@media (min-width: 768px) {
  .confirmation-container {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }

  .image-container,
  .form-container {
    flex: 1;
  }

  .image-container {
    margin-right: 2rem;
  }

  .form-container {
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .confirmation-container {
    padding: 20px 10px;
  }

  .illustration {
    max-width: 50%;
  }

  .form-container h1 {
    font-size: 2rem;
  }

  .form-container .form-control,
  .form-container .btn-primary {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .form-container h1 {
    font-size: 1.5rem;
  }

  .form-container .form-control,
  .form-container .btn-primary {
    max-width: 100%;
  }
}

/* Carousel.css */
.carousel-container {
  margin: 20px 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 70px; /* Adjusted width to accommodate image and padding */

}

.carousel-container .carousel-item img {
  border-radius: 10px;
  color: white;
  width: 50px; /* Set the width to 50px */
  height: auto; /* Maintain aspect ratio */
}

.carousel-container .carousel-caption {
  display: none; /* Hide captions to maintain small size */
}
/*MENU DROP CSS*/
.language-dropdown {
  position: absolute;
  background-color: #2980b9;
  right: 10px;
  top: 10px;
}

.language-dropdown .dropdown-menu {
  right: 0;
  left: auto;
}
.language-dropdown .dropdown-menu {
  /* position: absolute !important;
  margin: 0 !important; */
  z-index: 1050;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}


/* .card-container {
  perspective: 1000px;
}

.card-side {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.front {
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}

.flipped .front {
  transform: rotateY(180deg);
}

.flipped .back {
  transform: rotateY(360deg);
} */













